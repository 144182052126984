.messenger {
  display: grid;
  width: 100%;
  background: #eeeef1;

  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}