.spinner {
    z-index: 150;
    width: 100%;
    height: 100%;
}

.spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
}